import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://6ee6025b58e5a0cda45da4b80b6163cd@o4506503710703616.ingest.sentry.io/4506503712604160",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});